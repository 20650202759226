import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrBoosterFragment = {
  __typename?: 'FdrBooster';
  id: string;
  mainText: string;
  frequency?: Types.BoosterFrequency | null;
  duration: number;
};

export const FdrBoosterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrBooster' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrBooster' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
