import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrWebMetaFragmentDoc } from './fdr-web-meta.generated';
export type FdrProductWebMetaFragment = {
  __typename?: 'FdrProductWebMeta';
  descriptionWebMeta?: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  regionWebMeta?: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  resortWebMeta?: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  ratingsWebMeta?: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  offersWebMeta?: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  mapWebMeta?: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  climateWebMeta?: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
};

export const FdrProductWebMetaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductWebMeta' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductWebMeta' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptionWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regionWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resortWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ratingsWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offersWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mapWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'climateWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrWebMetaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
